import { flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getContractTrustedResume } from '~/api/api';
import { TRUSTED_RESUME_STATE } from '~/components/Blocks/Templates/DeferredPayment/DeferredPayment.types';

const RequestsStateModel = types.model('State', {
  getContractTrustedResume: createApiPathModel(
    'GET /Finances/ContractTrustedResume/Get',
  ),
});

const DeferredPaymentDataModel = types.model('DeferredPaymentModel', {
  /** Подключена ли услуга в данный момент. */
  /** Возможность подключения услуги. */
  /** Продолжительность предоставления услуги в часах. */
  durationHours: types.maybeNull(types.number),
  durationSecondsLeft: types.maybeNull(types.number),
  trustedResumeState: types.maybeNull(
    types.enumeration(Object.values(TRUSTED_RESUME_STATE)),
  ),
});

export const DeferredPaymentModel = types
  .model('DeferredPayment', {
    requestsState: RequestsStateModel,
    deferredPayment: types.maybeNull(DeferredPaymentDataModel),
  })
  .views((self) => ({
    get isDeferredPayment() {
      return !!self.deferredPayment;
    },
    get isActivated() {
      return (
        self.deferredPayment?.trustedResumeState ===
        TRUSTED_RESUME_STATE.activated
      );
    },
    get isAvailable() {
      return (
        self.deferredPayment?.trustedResumeState ===
        TRUSTED_RESUME_STATE.available
      );
    },
    get isUsed() {
      return (
        self.deferredPayment?.trustedResumeState === TRUSTED_RESUME_STATE.used
      );
    },
    get durationHours() {
      return self.deferredPayment?.durationHours;
    },
    get durationSecondsLeft() {
      return self.deferredPayment?.durationSecondsLeft;
    },
  }))
  .actions((self) => ({
    getContractTrustedResume: flow(function* () {
      self.requestsState.getContractTrustedResume.reset();
      self.requestsState.getContractTrustedResume.setLoading();
      try {
        const res = yield getContractTrustedResume();
        self.deferredPayment = res;
        self.requestsState.getContractTrustedResume.setSuccess();
      } catch (e) {
        self.requestsState.getContractTrustedResume.setFail();
      }
    }),
    resetDeferredPayment: () => {
      self.deferredPayment = null;
    },
  }));

export const DeferredPaymentModelInstance = DeferredPaymentModel.create({
  requestsState: {
    getContractTrustedResume: defaultModelState,
  },
  deferredPayment: null,
});
