/** libraries */
import { applySnapshot, flow, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** api */
import {
  getChannelsByTariffId,
  getITvChannelOrderList,
  getITvChannelPackList,
  getITvTimeshiftInfo,
  getITvTrialPeriodCondition,
  getTransformerStatus,
  getWinkInfo,
} from '~/api/apiPab2c';
/** interfaces */
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import {
  TVChannelsModel,
  AllPackageModel,
  TrialInfoModel,
  TransformerInfoModel,
  ChannelModel,
} from './models/TVModel';
import { WinkConnectionStatus } from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

const RequestsStateModel = types.model('State', {
  getTransformerStatus: createApiPathModel(
    'GET /Itv/Transformer/TransformerStatusGet',
  ),
  getChannelsByTariffId: createApiPathModel('GET /Itv/GetChannelsByTariffId'),
  getITvChannelPackList: createApiPathModel(
    'GET /Itv/ContractItv/ITvChannelPackListIncludeFree',
  ),
  getITvChannelOrderList: createApiPathModel(
    'GET /Itv/ContractItv/ITvChannelOrderList',
  ),
  getITvTrialPeriodCondition: createApiPathModel(
    'GET /Itv/ContractItv/ITvTrialPeriodCondition',
  ),
  getWink: createApiPathModel('GET /Wink/Wink/GetWinkInfo'),
  getViewControlInfo: createApiPathModel(
    'GET /Itv/ContractItv/ITvTimeshiftInfoGet',
  ),
});

export const TVStore = types
  .model('TV', {
    requestsState: RequestsStateModel,
    tvchannels: types.array(TVChannelsModel),
    packages: types.array(AllPackageModel),
    connectedPackagesAndOutsideChannels: types.array(AllPackageModel),
    trial: types.maybeNull(TrialInfoModel),
    transformerInfo: types.maybeNull(TransformerInfoModel),
    channelsData: types.array(TVChannelsModel),
    tvChannelPackagesData: types.array(AllPackageModel),
    connectedTVPackagesData: types.array(AllPackageModel),
    channelsListData: types.array(ChannelModel),
    connectedChannelsInPackagesData: types.array(types.number),
    connectedChannelsOutsidePackagesData: types.array(types.number),
    isLoadingChannelsAndPackages: (types.boolean, false),
    /** Информация о состоянии услуги "Wink" */
    wink: types.maybeNull(
      types.model({
        accountStatus: types.enumeration(Object.values(WinkConnectionStatus)),
        ottCode: types.string,
      }),
    ),
    /** Информация о состоянии услуги "Управление просмотром" */
    viewControl: types.maybeNull(
      types.model({
        isOrdered: types.boolean,
        banTrimDate: types.maybeNull(types.string),
        pendingRejectionDate: types.maybeNull(types.string),
        price: types.number,
        currentTypeCode: types.maybeNull(types.string),
      }),
    ),
    /** Сумма заказа трансформера при редактировании */
    editOrderSum: types.maybeNull(types.number),
    /** ids добавленных в трансформер пакетов */
    idsAddedPacketsToTransformerData: types.array(types.number),
    /** ids добавленных в трансформер каналов */
    idsAddedChannelsToTransformerData: types.array(types.number),
  })
  .views((self) => ({
    get isTVData() {
      return (
        !!self.requestsState.getChannelsByTariffId.isFetched ||
        !!self.requestsState.getITvChannelPackList.isFetched ||
        !!self.requestsState.getITvChannelOrderList.isFetched ||
        !!self.requestsState.getITvTrialPeriodCondition.isFetched
      );
    },
    get channels() {
      return toJS(self.channelsData);
    },
    get tvChannelPackages() {
      return toJS(self.tvChannelPackagesData);
    },
    get connectedTVPackages() {
      return toJS(self.connectedTVPackagesData);
    },
    get channelsList() {
      return toJS(self.channelsListData);
    },
    get connectedChannelsInPackages() {
      return toJS(self.connectedChannelsInPackagesData);
    },
    get connectedChannelsOutsidePackages() {
      return toJS(self.connectedChannelsOutsidePackagesData);
    },
    /** Подключённые каналы вне пакетов */
    get channelsOutsidePackages() {
      if (
        self.connectedChannelsOutsidePackagesData?.length &&
        self.channelsListData?.length
      ) {
        return toJS(
          self.channelsListData.filter((item) =>
            self.connectedChannelsOutsidePackagesData.includes(
              item.weburgId ?? item.id,
            ),
          ),
        );
      }
      return [];
    },
    get winkInfo() {
      return toJS(self.wink);
    },
    get viewControlInfo() {
      return toJS(self.viewControl);
    },
    get idsAddedPacketsToTransformer() {
      return toJS(self.idsAddedPacketsToTransformerData);
    },
    get idsAddedChannelsToTransformer() {
      return toJS(self.idsAddedChannelsToTransformerData);
    },
    get isLoading() {
      return (
        self.isLoadingChannelsAndPackages ||
        self.requestsState.getWink.isLoading ||
        self.requestsState.getViewControlInfo.isLoading
      );
    },
  }))
  .actions((self) => ({
    getTransformerStatus: flow(function* () {
      self.requestsState.getTransformerStatus.reset();
      self.requestsState.getTransformerStatus.setLoading();
      try {
        const res = yield getTransformerStatus();
        self.transformerInfo = res;
        self.editOrderSum = res?.orderSum ?? 0;
        self.requestsState.getTransformerStatus.setSuccess();
      } catch (e) {
        self.requestsState.getTransformerStatus.setFail();
      }
    }),
  }))
  .actions((self) => ({
    updateChannelsAndPackages: flow(function* (tariffId) {
      try {
        self.requestsState.getChannelsByTariffId.reset();
        self.requestsState.getITvChannelPackList.reset();
        self.requestsState.getITvChannelOrderList.reset();
        self.requestsState.getITvTrialPeriodCondition.reset();
        self.requestsState.getChannelsByTariffId.setLoading();
        self.requestsState.getITvChannelPackList.setLoading();
        self.requestsState.getITvChannelOrderList.setLoading();
        self.requestsState.getITvTrialPeriodCondition.setLoading();
        const tvchannels = yield getChannelsByTariffId(tariffId);
        const packages = yield getITvChannelPackList();
        const connectedPackagesAndOutsideChannels = yield getITvChannelOrderList();
        const trialInfo = yield getITvTrialPeriodCondition();
        /** Полный список каналов из tvchannels */
        const allTVChannels = tvchannels.flatMap((item) => item.channels);

        /** Полный список каналов из packages */
        const allChannels = packages.reduce((acc, item) => {
          const channelsArr = item.channelPackChannels.filter((pack) => {
            return !acc.find((canal) => canal.id === pack.id);
          });
          acc.push(...channelsArr);
          return acc;
        }, []);

        /** Объединение каналов */
        const jointChannels = allChannels.map((item) => {
          const canal = allTVChannels.find(
            (itemChannel) => itemChannel.weburgId === item.id,
          );

          /** Каналы вне пакетов */
          const outsideChannel = connectedPackagesAndOutsideChannels
            .filter((itemChannel) => itemChannel.isChannelWrapper)
            .find((itemChannel) => itemChannel.channelIds[0] === item.id);
          return {
            ...item,
            ...canal,
            ...outsideChannel,
          };
        });

        applySnapshot(self.channelsListData, jointChannels);

        const jointTVChannels = tvchannels.map((item) => {
          const canals = item.channels.map((itemCanal) => {
            const canal = allChannels.find(
              (channel) => channel.id === itemCanal.weburgId,
            );
            /** Каналы вне пакетов */
            const outsideChannel = connectedPackagesAndOutsideChannels
              .filter((itemChannel) => itemChannel.isChannelWrapper)
              .find(
                (itemChannel) =>
                  itemChannel.channelIds[0] === itemCanal.weburgId,
              );
            return {
              ...itemCanal,
              ...canal,
              ...outsideChannel,
            };
          });
          return {
            ...item,
            channels: canals,
          };
        });

        applySnapshot(self.channelsData, jointTVChannels);

        /** Подключённые пакеты */
        const connectedPackages = connectedPackagesAndOutsideChannels
          .filter((item) => !item.isChannelWrapper)
          .sort((a, b) => Number(b.isFree) - Number(a.isFree))
          .map((item) => {
            return {
              ...item,
              name: null,
              id: null,
              channelPackChannels: null,
            };
          });
        applySnapshot(self.connectedTVPackagesData, connectedPackages);

        if (self.transformerInfo) {
          const packs = connectedPackages.map((item) => item.channelPackId);
          applySnapshot(self.idsAddedPacketsToTransformerData, packs);
        }

        /** Все пакеты */
        const allChannelPackages = packages
          .filter((pack) => !pack.isChannelWrapper)
          .map((item) => {
            const extendedChannel = item?.channelPackChannels.map((channel) => {
              return jointChannels.find(
                (canal) => canal.weburgId === channel.id,
              );
            });
            return {
              ...item,
              channelPackChannels: extendedChannel,
            };
          });

        /** Объединение allChannelPackages и connectedPackages */
        const jointPackages = [
          ...allChannelPackages,
          ...connectedPackages,
        ].reduce((acc, item) => {
          // eslint-disable-next-line no-param-reassign
          acc = acc.filter((canal) => {
            return canal.id !== item.channelPackId;
          });
          /** Убираем акционные пакеты, если договору больше 180 дней  */
          if (item.id === trialInfo.channelPackId && !trialInfo.isAvailable) {
            return acc;
          }
          acc.push(item);
          return acc;
        }, []);
        applySnapshot(self.tvChannelPackagesData, jointPackages);

        /** id`s подключённых каналов в пакете */
        const connectedInPackage = connectedPackages.reduce((acc, item) => {
          // eslint-disable-next-line no-param-reassign
          item.channelIds.forEach((id) => {
            if (!acc.includes(id)) acc.push(id);
          });
          return acc;
        }, []);
        applySnapshot(self.connectedChannelsInPackagesData, connectedInPackage);

        /**  id`s подключённых каналов вне пакетов */
        const connectedOutsidePackage = connectedPackagesAndOutsideChannels
          .filter((item) => item.isChannelWrapper)
          .map((item) => item.channelIds[0]);
        applySnapshot(
          self.connectedChannelsOutsidePackagesData,
          connectedOutsidePackage,
        );

        if (self.transformerInfo) {
          const ids = jointChannels.reduce((acc: number[], item) => {
            if (connectedOutsidePackage.includes(item.weburgId ?? item.id))
              acc.push(item?.singleChannelPacks[0].id);
            return acc;
          }, []);
          applySnapshot(self.idsAddedChannelsToTransformerData, ids);
        }

        self.isLoadingChannelsAndPackages = false;
        self.requestsState.getChannelsByTariffId.setSuccess();
        self.requestsState.getITvChannelPackList.setSuccess();
        self.requestsState.getITvChannelOrderList.setSuccess();
        self.requestsState.getITvTrialPeriodCondition.setSuccess();
      } catch (e) {
        console.error(e, 'updateChannelsAndPackages');
        self.requestsState.getChannelsByTariffId.setFail();
        self.requestsState.getITvChannelPackList.setFail();
        self.requestsState.getITvChannelOrderList.setFail();
        self.requestsState.getITvTrialPeriodCondition.setFail();
      }
    }),
    setIsLoadingChannelsAndPackages: (isLoadingChannelsAndPackages) => {
      self.isLoadingChannelsAndPackages = isLoadingChannelsAndPackages;
    },
    getWink: flow(function* () {
      self.requestsState.getWink.reset();
      self.requestsState.getWink.setLoading();
      try {
        const res = yield getWinkInfo();
        self.wink = res;
        self.requestsState.getWink.setSuccess();
      } catch (e) {
        console.error(e, 'getWink');
        self.requestsState.getWink.setFail();
        self.wink = null;
      }
    }),
    getViewControlInfo: flow(function* () {
      self.requestsState.getViewControlInfo.reset();
      self.requestsState.getViewControlInfo.setLoading();
      try {
        const res = yield getITvTimeshiftInfo();
        self.viewControl = res;
        self.requestsState.getViewControlInfo.setSuccess();
      } catch (e) {
        console.error(e, 'getViewControlInfo');
        self.requestsState.getViewControlInfo.setFail();
        self.wink = null;
      }
    }),
    setEditOrderSum: (sum: number) => {
      self.editOrderSum = sum;
    },
    setIdsAddedPacketsToTransformer: (ids: number[]) => {
      applySnapshot(self.idsAddedPacketsToTransformerData, ids);
    },
    setIdsAddedChannelsToTransformer: (ids: number[]) => {
      applySnapshot(self.idsAddedChannelsToTransformerData, ids);
    },
    resetTV: () => {
      self.tvchannels.clear();
      self.packages.clear();
      self.connectedPackagesAndOutsideChannels.clear();
      self.trial = null;
      self.transformerInfo = null;
      self.channelsData.clear();
      self.tvChannelPackagesData.clear();
      self.connectedTVPackagesData.clear();
      self.channelsListData.clear();
      self.connectedChannelsInPackagesData.clear();
      self.connectedChannelsOutsidePackagesData.clear();
      self.isLoadingChannelsAndPackages = false;
      self.wink = null;
      self.viewControl = null;
      self.idsAddedPacketsToTransformerData.clear();
      self.idsAddedChannelsToTransformerData.clear();
      self.editOrderSum = 0;
    },
  }));

export const TVStoreInstance = TVStore.create({
  requestsState: {
    getTransformerStatus: defaultModelState,
    getChannelsByTariffId: defaultModelState,
    getITvChannelPackList: defaultModelState,
    getITvChannelOrderList: defaultModelState,
    getITvTrialPeriodCondition: defaultModelState,
    getWink: defaultModelState,
    getViewControlInfo: defaultModelState,
  },
  tvchannels: [],
  packages: [],
  connectedPackagesAndOutsideChannels: [],
  trial: null,
  transformerInfo: null,
  channelsData: [],
  tvChannelPackagesData: [],
  connectedTVPackagesData: [],
  channelsListData: [],
  connectedChannelsInPackagesData: [],
  connectedChannelsOutsidePackagesData: [],
  isLoadingChannelsAndPackages: false,
  wink: null,
  viewControl: null,
  editOrderSum: 0,
  idsAddedPacketsToTransformerData: [],
  idsAddedChannelsToTransformerData: [],
});
