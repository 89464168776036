export const initialInput = {
  forSend: '',
  value: '',
};

export const initialAutocomplete = {
  label: '',
  value: '',
};

// state result по умолчанию
export const DEFAULT_RESULT = {
  isResult: false,
  isCorrect: true,
};

export const DEFAULT_ERROR = 'Что-то пошло не так, повторите попытку позже';

export const NEW = [
  'новый комментарий',
  'новых комментария',
  'новых комментариев',
];

export const DEMAND_QP = 'demand';

export const TOO_MUCH_REQUESTS_ERROR_TEXT =
  'Превышено количество заявок за\u00A0день. Новую вы можете отправить завтра.';
