/** ошибка, которая отображается в крайнем случае */
export const DEFAULT_ERROR = 'DefaultException';

/** сообщения об ошибках ввода */
export enum ERROR_MESSAGE {
  AuthCountException = 'Неправильный номер договора и/или пароль',
  ContractNotServicedException = 'Договор не обслуживается',
  ContractNotFoundException = 'Договор не найден',
  BlockException = 'Доступ в\u00A0раздел «Моя\u00A0Планета» временно заблокирован',
  ArgumentNullException = 'Введите пароль',
  DefaultException = 'Что-то пошло не так',
}

export enum RESPONSE_STATUS {
  status400 = 400,
  status500 = 500,
}
