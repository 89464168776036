/* eslint-disable no-throw-literal */
/** библиотеки */
import fetch from 'isomorphic-unfetch';
import https from 'https';
import Cookies, { CookieChangeOptions } from 'universal-cookie';

/** интерфейсы */
import { City, CityData } from '~/interfaces/CityInterface';
import { SummaryFields } from '~/components/Blocks/Templates/Summary/Summary.types';
import { FeedInfo } from '~/components/Feed/FeedPage.types';
import { SpeedFields } from '~/components/Blocks/Templates/Speed/Speed.types';
import { DeviceFields } from '~/components/Blocks/Templates/Device/Device.types';
import { DevicesFields } from '~/components/Blocks/Templates/Devices/interfaces';
import { LegalInfo } from '~/components/Blocks/Templates/Legal/Legal.types';
import {
  ConnectionDemand,
  RequestDataTPProps,
  TechnicalPossibility,
} from '~/components/ConnectionWizard/ConnectionWizard.types';
import { ContactPhone } from '~/interfaces/ContactPhone.interface';

import {
  AnnouncementContentProps,
  AnnouncementFieldsContentProps,
  AnnouncementsTagsByCity,
} from '~/components/Blocks/Templates/Announcements/Announcements.types';
import {
  BanksPropsData,
  CardPaymentRegisterOrder,
  CheckCardPaymentOrderState,
  CheckCardPaymentOrderStateForCardBinding,
  PaymentCardProps,
  SbpPaymentCheckOrderByBindingStateProps,
  SbpPaymentCheckOrderStateProps,
  SbpPaymentProps,
} from '~/components/Blocks/Templates/Payment/types';
import { ContractAuth } from '~/interfaces/ContractAuth.interface';
import { CardPaymentBindAutoPaymentProps } from '~/components/Blocks/Templates/AutoPayment/types';
import { ContractTrustedResumeProps } from '~/components/Blocks/Templates/DeferredPayment/DeferredPayment.types';
import { SetAnswerInfo } from '~/components/Blocks/Templates/MyStoriesContest/MyStoriesContest.types';
import { DataProps, PAYMENT_TYPE } from '~/interfaces/PromoInterface';
import {
  BindData,
  SoftlineSubscriptionFieldsData,
} from '~/components/Blocks/Templates/Softline/types';
import { ShippingForSubscription } from '~/components/Blocks/Templates/Softline/DescriptionOfSoftlineSubscription/types';
import {
  SbpBindingCheckInfoProps,
  SbpBindingsProps,
  SbpInfo,
  SbpInfoByBinding,
  SbpRegisterBindingInfo,
} from '~/components/Blocks/Templates/PaymentResult/interfaces';
import { PhoneTemplate } from '~/components/CallbackWizard/interfaces';
import { MarketingGroups } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';
import { SummaryFieldsProps } from '~/components/Blocks/Templates/ProductSelector/ProductSelector.interfaces';
import { VacationInfoProps } from '~/components/Blocks/Templates/Vacation/interfaces';

/** константы */
import { TOKEN } from '~/constants/common';
import { ERROR_MESSAGE } from '~/components/AuthWizard/constants';

/** Утилиты */
import sentryWrapper from './utils/SentryWrapper';
import responseHandler from './utils/responseHandler';
import { SubjectWithChannels } from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

const isClient = typeof window !== 'undefined';

// Для production окружения приложение server-side ходит по отдельному url
export const baseUrl: string = isClient
  ? process.env.API_URL
  : process.env.API_INTERNAL_URL;

const parsedBaseUrl = new URL(baseUrl);

// TODO: Необходимо добавить сертификат. Пока костыль:
const options =
  !isClient && parsedBaseUrl.protocol === 'https:'
    ? {
        agent: new https.Agent({
          rejectUnauthorized: false,
        }),
      }
    : {};

export const reqData: RequestInit = {
  mode: 'cors',
  ...{
    ...options,
  },
};

/**
 * Временно (до вывода Планеты 2.0 или соответствующих правок в ней):
 * - при получении списка городов getCities заменить Березовский на Берёзовский;
 * - при проверке ТВ getTechnicalPossibility заменять Берёзовский на Березовский;
 */
const BZK_NAME_OLD = 'Березовский';
const BZK_NAME_NEW = 'Берёзовский';

const getValidCityName = (cityName: string): string =>
  cityName === BZK_NAME_NEW ? BZK_NAME_OLD : cityName;

export const cookies = new Cookies();
let token = cookies.get(TOKEN);

cookies.addChangeListener((params: CookieChangeOptions) => {
  if (params.name === TOKEN) {
    token = String(params.value);
  }
});
// Метрики
const yandexMetricaClientID = cookies.get('_ym_uid') ?? '';
const googleAnalyticsClientID = cookies.get('_ga') ?? '';

/**
 * Добавляет заголовок авторизации к объекту заголовков
 * @param headers {object} объект с заголовками
 * @return {object} объект со старыми заголовками и авторизацией
 */
export const getHeadersWithAuth = (
  headers?: HeadersInit | null,
  temporaryToken?: string,
): HeadersInit =>
  headers
    ? { ...headers, Authorization: `Bearer ${temporaryToken || token}` }
    : { Authorization: `Bearer ${temporaryToken || token}` };
/**
 * Возвращает все Ленты.
 */
export const getFeeds = async (): Promise<FeedInfo[]> => {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
  const data = await fetch(`${baseUrl}/Feeds`, reqData);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getFeeds', data.status, res);
  return res;
};

/**
 * Проверяет доступность url
 * @url - адрес
 * */
export const checkUrl = async (url: string): Promise<number> =>
  (await fetch(url, { mode: 'no-cors' })).status;

/**
 * Возвращает Ленту.
 * @param slug Строка пути.
 * @param preview Режим предосмотра.
 */
export const getFeedBySlug = async (
  slug: string,
  preview: boolean,
): Promise<FeedInfo> => {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
  const data = await fetch(
    `${baseUrl}/Feeds/${slug}${preview ? `?preview=${preview}` : ''}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok && data.status !== 404)
    sentryWrapper('getFeedBySlug', data.status, res);
  return res;
};

/**
 * Возвращает город по IP-адресу.
 */
export const getCityByIpAddress = async (): Promise<CityData> => {
  const data = await fetch(`${baseUrl}/City/City/GetCityByIpAddress`, reqData);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getCityByIpAddress', data.status, res);
  return res;
};

/**
 * Возвращает список городов
 */
export const getCities = async (): Promise<CityData[]> => {
  const data = await fetch(`${baseUrl}/City/City/GetCities`, reqData);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getCities', data.status, res);
  /** Временно (до вывода Планеты 2.0 или соответствующих правок в ней),
   * при получении списка городов заменить Березовский на Берёзовский;
   */
  const resMapped = res.map((item: City) => {
    if (item.name === BZK_NAME_OLD) {
      return { ...item, name: BZK_NAME_NEW };
    }
    return item;
  });
  return resMapped;
};

/**
 * Возвращает список всех контактов ОТП и ОКП для всех городов
 */
export const getContactPhones = async (): Promise<ContactPhone[]> => {
  const data = await fetch(`${baseUrl}/City/City/GetContactPhones`, reqData);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getContactPhones', data.status, res);
  return res;
};

/**
 * Возвращает список идентификаторов городов по коду серии продукта.
 * @param seriesCode Код серии продукта.
 */
export const GetCitiesBySeriesCode = async (
  seriesCode: string,
): Promise<number[]> => {
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetCitiesBySeriesCode?seriesCode=${seriesCode}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('GetCitiesBySeriesCode', data.status, res);
  return res;
};

/**
 * Возвращает общую информацию о продукте.
 * @param seriesCode Код серии продукта.
 * @param {Array<string>} promoCodes Промокоды продукта
 */
export const getSummaryBySeriesCode = async (
  seriesCode: string,
  promoCodes?: string[],
): Promise<SummaryFields> => {
  let promoQuery: string;
  if (promoCodes && promoCodes.length > 0) {
    promoQuery = promoCodes.map((item) => `&promoCodes=${item}`).join('');
  }
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetSummary?seriesCode=${seriesCode}${
      promoQuery || ''
    }`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSummaryBySeriesCode', data.status, res);
  return res;
};

/**
 * Проверка авторизации
 */
export const checkAuth = async (
  temporaryToken?: string,
): Promise<{ status: number }> => {
  const data = await fetch(`${baseUrl}/Security/Auth/CheckAuth`, {
    ...reqData,
    headers: getHeadersWithAuth(null, temporaryToken),
  });
  return { status: data.status };
};

/** Авторизация по IP-адресу */
export const authByIp = async (): Promise<ContractAuth> => {
  const data = await fetch(`${baseUrl}/Security/Auth/AuthByIp`, {
    ...reqData,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  const res = await data.json();
  if (!data.ok && data.status !== 400)
    sentryWrapper('authByIp', data.status, res);
  return res;
};

/** Авторизация по логину и паролю */
export const authByPassword = async (
  contractName: string,
  password: string,
): Promise<ContractAuth> => {
  const data = await fetch(
    `${baseUrl}/Security/Auth/AuthByPassword?contractName=${contractName}&password=${password}`,
    {
      ...reqData,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  const res = await data.json();
  if (
    !data.ok &&
    data.status !== 400 &&
    res.Type !== ERROR_MESSAGE.ArgumentNullException
  )
    sentryWrapper('authByPassword', data.status, res);
  return res;
};

/** Авторизация из-под сотрудника */
export const authByTemporaryToken = async (
  contractName: string,
  temporaryToken: string,
): Promise<ContractAuth> => {
  const data = await fetch(
    `${baseUrl}/Security/Auth/AuthByTemporaryToken?contractName=${contractName}&temporaryToken=${temporaryToken}`,
    {
      ...reqData,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  const res = await data.json();
  if (
    !data.ok &&
    data.status !== 400 &&
    res.Type !== ERROR_MESSAGE.ArgumentNullException
  )
    sentryWrapper('authByTemporaryToken', data.status, res);
  return res;
};

/**
 * Возвращает список каналов.
 * @param {string} seriesCode Код серии продукта
 */
export const getChannelsBySeriesCode = async (
  seriesCode?: string,
): Promise<SubjectWithChannels[]> => {
  const data = await fetch(
    `${baseUrl}/Itv/GetChannels${
      seriesCode ? `?seriesCode=${seriesCode}` : ''
    }`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getChannelsBySeriesCode', data.status, res);
  return res;
};

/**
 * Возвращает информацию для блока Legal
 * @param seriesCode
 */
export const getLegal = async (seriesCode: string): Promise<LegalInfo> => {
  const data = await fetch(
    `${baseUrl}/Legal/GetLegal?seriesCode=${seriesCode}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getLegal', data.status, res);
  return res;
};

/**
 * Возвращает поля для блока connection speed.
 * @param seriesCode Код серии продукта
 */
export const getConnectionSpeed = async (
  seriesCode: string,
): Promise<SpeedFields> => {
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetConnectionSpeed?seriesCode=${seriesCode}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getConnectionSpeed', data.status, res);
  return res;
};

/**
 * Возвращает информацию об устройстве.
 * @param {string} seriesCode Код серии продукта
 * @param {string} type Тип устройства
 * @param {Array<string>} promoCodes Промокоды продукта
 * @returns {Promise<DeviceFields>}
 */
export const getDevice = async (
  seriesCode: string,
  type: string,
  promoCodes: string[],
): Promise<DeviceFields> => {
  let promoQuery: string;
  if (promoCodes && promoCodes.length > 0) {
    promoQuery = promoCodes.map((item) => `&promoCodes=${item}`).join('');
  }
  const data = await fetch(
    `${baseUrl}/Device/Device/GetDevices?seriesCode=${seriesCode}&deviceType=${type}${
      promoQuery || ''
    }`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getDevice', data.status, res);
  const [device] = res;
  return device;
};

/**
 * Возвращает информацию об устройствах
 * @param planetaDevices - отобразить только устройства Планеты
 * @returns {Promise<DevicesFields>}
 */
export const getDevices = async (
  planetaDevices?: boolean,
): Promise<DevicesFields[]> => {
  const data = await fetch(
    `${baseUrl}/Device/Device/GetAllDevices?planetaDevices=${
      planetaDevices ?? true
    }`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getDevices', data.status, res);
  return res;
};

/**
 * Получает информацию о технической возможности.
 * @param {string} cityName Город
 * @param {string} streetName Улица
 * @param {string} buildingName Дом
 * @param {string} flatName Квартира
 * @returns {Promise<TechnicalPossibility>}
 */
export const getTechnicalPossibility = async (
  cityName: string,
  streetName: string,
  buildingName: string,
  flatName: string,
): Promise<TechnicalPossibility> => {
  const fromUrl = window.location.href;

  const requestData: RequestDataTPProps = {
    cityName: getValidCityName(cityName),
    streetName,
    buildingName,
    flatName,
  };

  requestData.fromUrl = fromUrl;
  requestData.yandexMetricaClientID = yandexMetricaClientID;
  requestData.googleAnalyticsClientID = googleAnalyticsClientID;

  const data = await fetch(
    `${baseUrl}/TechnicalPossibility/TechnicalPossibility/GetTechnicalPossibility`,
    {
      ...reqData,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getTechnicalPossibility', data.status, res);
  return res;
};

/**
 * Получает названия улиц по переданным параметрам.
 * @param {string} cityName Город
 * @param {string} streetName Улица
 * @returns {Promise<string[]>}
 */
export const searchStreetName = async (
  cityName: string,
  streetName: string,
): Promise<string[]> => {
  const data = await fetch(
    `${baseUrl}/TechnicalPossibility/TechnicalPossibility/SearchStreetName?cityName=${getValidCityName(
      cityName,
    )}&streetName=${streetName}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('searchStreetName', data.status, res);
  return res.streetNames;
};

/**
 * Получает названия зданий по переданным параметрам.
 * @param {string} cityName Город
 * @param {string} streetName Улица
 * @param {string} house Дом
 * @returns {Promise<string[]>}
 */
export const searchBuildingName = async (
  cityName: string,
  streetName: string,
  house: string,
): Promise<string[]> => {
  const data = await fetch(
    `${baseUrl}/TechnicalPossibility/TechnicalPossibility/SearchBuildingName?cityName=${getValidCityName(
      cityName,
    )}&streetName=${streetName}&house=${house}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('searchBuildingName', data.status, res);
  return res.buildingNames;
};

/**
 * Создаёт заявку на подключение дома (Отсутствие технической возможности).
 * @returns {Promise<number>}
 */
export const createConnectionDemand = async (
  demandData: ConnectionDemand,
): Promise<{ status: number }> => {
  /**
   * Временно (до вывода Планеты 2.0 или соответствующих правок в ней) заменять
   * Берёзовский на Березовский для отправки
   */
  demandData.city = getValidCityName(demandData.city);
  demandData.yandexMetricaClientID = yandexMetricaClientID;
  demandData.googleAnalyticsClientID = googleAnalyticsClientID;
  let data;
  try {
    data = await fetch(
      `${baseUrl}/CallToAction/CallToAction/CreateConnectionDemand`,
      {
        ...reqData,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(demandData),
      },
    );
  } catch (e) {
    const res = await data.json();
    sentryWrapper('createConnectionDemand', data.status, res);
  }
  return data;
};

/**
 * Создаёт заявку на заключение договора (Наличие технической возможности).
 * @returns {Promise<any>}
 */
export const createContractDemand = async (
  demandData: ConnectionDemand,
): Promise<{ status: number }> => {
  /**
   * Временно (до вывода Планеты 2.0 или соответствующих правок в ней) заменять
   * Берёзовский на Березовский для отправки
   */
  demandData.city = getValidCityName(demandData.city);
  let data;
  try {
    data = await fetch(
      `${baseUrl}/CallToAction/CallToAction/CreateContractDemand`,
      {
        ...reqData,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(demandData),
      },
    );
  } catch (e) {
    const res = await data.json();
    sentryWrapper('createContractDemand', data.status, res);
  }
  return data;
};

/** Возвращает информацию для блока CallToAction
 * @param {string} seriesCode Код серии продукта
 * @param {Array<string>} promoCodes Промокоды продукта
 * */
export const getCallToAction = async (
  seriesCode: string,
  promoCodes: string[],
) => {
  let promoQuery: string;
  if (promoCodes && promoCodes.length > 0) {
    promoQuery = promoCodes.map((item) => `&promoCodes=${item}`).join('');
  }
  const data = await fetch(
    `${baseUrl}/CallToAction/CallToAction/GetCallToAction?seriesCode=${seriesCode}${
      promoQuery || ''
    }`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getCallToAction', data.status, res);
  return res;
};

/**
 * Получает перечисление маркетинг групп
 */
export const getMarketingGroups = async (): Promise<MarketingGroups[]> => {
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetMarketingGroups`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getMarketingGroups', data.status, res);
  return res;
};

/**
 * Получает общую информацию о продуктах, необходимую в блоке summary по кодам серий
 */
export const getSummaries = async (
  seriesCodes: string[],
): Promise<SummaryFieldsProps[]> => {
  const seriesQuery: string = seriesCodes
    .map((item) => `seriesCodes=${item}&`)
    .join('');
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetSummaries?${seriesQuery.substring(
      0,
      seriesQuery.length - 1,
    )}`,
    {
      ...reqData,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSummaries', data.status, res);
  return res;
};

/**
 * Заказывает обратный звонок
 * @param {string} phoneNumber Номер телефона
 * @param {string} callbackUrl URL страницы, с которой заказан обратный звонок.
 * @param {string} phoneTemplateTypeId ClientServerKey. Наименование специально зашифровано. Результат выполнения ServerKey XOR ClientKey.
 * @param {string} deviceId FuncResult. Наименование специально зашифровано. Значение случайного ключа, хэш которого был в M:Cordis.Frontline.Planeta.API.Modules.CallbackOrder.Controllers.CallbackOrderController.GetPhoneTemplate(System.UInt64).
 * @returns {Promise<any>}
 */
export const getCallbackOrder = async (
  phoneNumber: string,
  callbackUrl: string,
  phoneTemplateTypeId: bigint,
  deviceId: bigint,
): Promise<{ status: number }> => {
  const data = await fetch(
    `${baseUrl}/CallbackOrder/CallbackOrder/CallbackOrder?phoneNumber=${phoneNumber}&callbackUrl=${callbackUrl}&phoneTemplateTypeId=${phoneTemplateTypeId}&deviceId=${deviceId}`,
    reqData,
  );
  if (!data.ok) sentryWrapper('getCallbackOrder', data.status, '');
  return data;
};

/**
 * Модель с данными защиты от спама. Названия полей замаскированы. Так и задумано.
 * @param {number} phoneTemplateId ClientKey. Ключ клиента.
 * @returns {Promise<PhoneTemplate>}
 */
export const getPhoneTemplate = async (
  phoneTemplateId: number,
): Promise<PhoneTemplate> => {
  const data = await fetch(
    `${baseUrl}/CallbackOrder/CallbackOrder/GetPhoneTemplate?phoneTemplateId=${phoneTemplateId}`,
    {
      ...reqData,
      method: 'POST',
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPhoneTemplate', data.status, '');
  return res;
};

/** Возвращает данные для Меню.
 * @param preview Режим предосмотра.
 */
export const getMenu = async (preview: boolean): Promise<FeedInfo> => {
  const data = await fetch(
    `${baseUrl}/Menu/${preview ? `?preview=${preview}` : ''}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getMenu', data.status, res);
  return res;
};

/**
 * Получить анонсы лент новостей.
 * @param tags Список тэгов. Если не задан, получаем все.
 * @param cityId Идентификатор города
 * @param page Страница списка.
 * @param currentSlug
 * @param filterSlug slug новости
 */
export const getAnnouncements = async (
  tags: string[] = [],
  cityId?: number,
  page?: number,
  currentSlug?: string,
  filterSlug?: string,
): Promise<AnnouncementFieldsContentProps> => {
  let data;
  if (tags?.length || page || cityId) {
    const reqParamsArray = tags.map((tag: string) => {
      const capitalTag = tag.charAt(0).toUpperCase() + tag.slice(1); // Необходим параметр с большой буквы
      return `tags=${encodeURI(capitalTag)}`;
    });
    if (page) reqParamsArray.push(`page=${page}`);
    if (cityId) reqParamsArray.push(`cityId=${cityId}`);
    if (currentSlug) reqParamsArray.push(`currentSlug=${currentSlug}`);
    if (filterSlug) reqParamsArray.push(`filterSlug=${filterSlug}`);
    data = await fetch(
      `${baseUrl}/Feeds/GetAnnouncements?${reqParamsArray.join('&')}`,
      reqData,
    );
  } else {
    data = await fetch(`${baseUrl}/Feeds/GetAnnouncements`, reqData);
  }
  const res = await data.json();
  if (!data.ok) sentryWrapper('getAnnouncements', data.status, res);
  return res;
};

export const getAnnouncementsTags = async (): Promise<AnnouncementsTagsByCity> => {
  const data = await fetch(`${baseUrl}/Feeds/GetDictionaryAnnouncementsTag`);

  const res = await data.json();
  if (!data.ok) sentryWrapper('getAnnouncementsTags', data.status, res);
  return res.dictionaryAnnouncementsTag;
};

/**
 * Получить анонсы лент новостей.
 * @param tags Список тэгов. Если не задан, получаем все.
 * @param cityId Идентификатор города.
 * @param count Количество записей.
 */
export const getAnnouncementsByCount = async (
  tags: string[] = [],
  cityId?: number,
  count?: number,
): Promise<AnnouncementFieldsContentProps> => {
  let data;
  if (tags?.length || count || cityId) {
    const reqParamsArray = tags.map((tag: string) => {
      const capitalTag = tag.charAt(0).toUpperCase() + tag.slice(1); // Необходим параметр с большой буквы
      return `tags=${encodeURI(capitalTag)}`;
    });
    if (count) reqParamsArray.push(`count=${count}`);
    if (cityId) reqParamsArray.push(`cityId=${cityId}`);
    data = await fetch(
      `${baseUrl}/Feeds/GetAnnouncementsByCount?${reqParamsArray.join('&')}`,
      reqData,
    );
  } else {
    data = await fetch(`${baseUrl}/Feeds/GetAnnouncementsByCount`, reqData);
  }
  const res = await data.json();
  if (!data.ok) sentryWrapper('GetAnnouncementsByCount', data.status, res);
  return res;
};

/**
 * Получает новость по slug
 * @param slug - slug
 */
export const getAnnouncementBySlug = async (
  slug: string,
): Promise<AnnouncementContentProps> => {
  const data = await fetch(
    `${baseUrl}/Feeds/GetAnnouncementBySlug?slug=${slug}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getAnnouncementBySlug', data.status, res);
  return res;
};

/**
 * Регистрация в Cordis заказа на проведение платежа с использованием банковских карт
 * @param contractName Название пополняемого договора.
 * @param amount Сумма (в рублях) ожидаемого платежа
 * @param backUrl Обратная ссылка
 * @returns {Promise<CardPaymentRegisterOrder>} Информация о состоянии платежа
 */
export const setCardPaymentRegisterOrder = async (
  contractName: string,
  amount: number,
  backUrl: string,
): Promise<CardPaymentRegisterOrder> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/CardPaymentRegisterOrderByContractName?contractName=${contractName}&amount=${amount}&backUrl=${backUrl}`,
    {
      ...reqData,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('setCardPaymentRegisterOrder', data.status, res);
  return res;
};

/**
 * Возвращает информацию о состоянии платежа с использованием банковских карт по названию договора
 * @param contractName Название договора.
 * @param mdOrderId Идентификатор платежа в банковской системе
 * @returns {Promise<CheckCardPaymentOrderState>} Информация о состоянии платежа
 */
export const checkCardPaymentOrderState = async (
  contractName: string,
  mdOrderId: string,
): Promise<CheckCardPaymentOrderState> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/CardPaymentCheckOrderStateByContractName?contractName=${contractName}&mdOrderId=${mdOrderId}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('checkCardPaymentOrderState', data.status, res);
  return res;
};

/**
 * Производит регистрацию в Cordis заказа на проведение платежа с привязкой к контрагенту банковской карты.
 * @param amount Сумма (в рублях) ожидаемого платежа.
 * @param backUrl Обратная ссылка.
 * @returns {Promise<CardPaymentRegisterOrder>} Информация о состоянии платежа c привязкой карты
 */
export const setRegisterCardPaymentWithCardBinding = async (
  amount: number,
  backUrl: string,
  temporaryToken?: string,
): Promise<CardPaymentRegisterOrder> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/RegisterCardPaymentWithCardBinding?amount=${amount}&backUrl=${backUrl}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(null, temporaryToken),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok)
    sentryWrapper('setRegisterCardPaymentWithCardBinding', data.status, res);
  return res;
};

/**
 * Проверяет состояние платежа с привязкой банковской карты к контрагенту
 * @param mdOrderId Идентификатор платежа в банковской системе
 * @returns {Promise<CheckCardPaymentOrderStateForCardBinding>} Информация о состоянии платежа c привязкой карты
 */
export const checkCardPaymentStateForCardBinding = async (
  mdOrderId: string,
  temporaryToken?: string,
): Promise<CheckCardPaymentOrderStateForCardBinding> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/CheckCardPaymentStateForCardBinding?mdOrder=${mdOrderId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(null, temporaryToken),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok)
    sentryWrapper('checkCardPaymentStateForCardBinding', data.status, res);
  return res;
};

/**
 * Регистрирует в Cordis заказ на проведение платежа по привязанной банковской карте.
 * @param contractName Наименование договора.
 * @param amount Сумма (в рублях) ожидаемого платежа.
 * @param backUrl Обратная ссылка.
 * @param cardBindingId Идентификатор привязанной банковской карты в Cordis.
 * @returns {Promise<CardPaymentRegisterOrder>} Информация о состоянии платежа c привязанной картой
 */
export const setRegisterOrderForContractByBindingPaymentCard = async (
  contractName: string,
  amount: number,
  backUrl: string,
  cardBindingId: number,
): Promise<CardPaymentRegisterOrder> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/RegisterOrderForContractByBindingPaymentCard?contractName=${contractName}&amount=${amount}&backUrl=${backUrl}&cardBindingId=${cardBindingId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok)
    sentryWrapper(
      'setRegisterOrderForContractByBindingPaymentCard',
      data.status,
      res,
    );
  return res;
};

/**
 * Получает информацию о привязанных банковских картах на договоре
 * @returns {Promise<PaymentCardProps>} Информация о привязанных банковских картах на договоре
 */
export const getPaymentCards = async (): Promise<PaymentCardProps[]> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/GetPaymentCards`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPaymentCards', data.status, res);
  return res;
};

/**
 * Отвязывает банковскую карту контрагента.
 * @param cardBindingId Идентификатор привязанной банковской карты в Cordis.

 * @returns {Promise<{ status: number }>} Информация об отвязке банковской карты контрагента.
 */
export const unbindPaymentCard = async (
  cardBindingId: number,
): Promise<number> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/UnbindPaymentCard?cardBindingId=${cardBindingId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) sentryWrapper('unbindPaymentCard', data.status, data);
  return data.status;
};

/**
 * Подключает/изменяет автоплатёж для договора.
 * @returns {Promise<CardPaymentBindAutoPaymentProps>} Информация об автоплатеже
 */
export const cardPaymentBindAutoPayment = async (
  cardBindingId: number,
  payment: number,
  hasAutoCalc: boolean,
  temporaryToken?: string,
): Promise<CardPaymentBindAutoPaymentProps> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/CardPaymentBindAutoPayment?bindingId=${cardBindingId}&payment=${payment}&hasAutoCalc=${hasAutoCalc}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(null, temporaryToken),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('CardPaymentBindAutoPayment', data.status, res);
  return res;
};

/**
 * Отключает автоплатёж для договора.
 * @returns {Promise<boolean>} Флаг отключения
 */
export const cardPaymentUnBindAutoPayment = async (): Promise<boolean> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/CardPaymentUnBindAutoPayment`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('cardPaymentUnBindAutoPayment', data.status, null);
    return false;
  }

  return true;
};

/**
 * Возвращает информацию об автоплатеже для авторизованного договора
 * @returns {Promise<CardPaymentBindAutoPaymentProps>} Информация об автоплатеже
 */
export const getCardPaymentAutoPaymentInfo = async (
  temporaryToken?: string,
): Promise<CardPaymentBindAutoPaymentProps> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/CardPaymentAutoPaymentInfo`,
    {
      ...reqData,
      method: 'GET',
      headers: getHeadersWithAuth(null, temporaryToken),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('CardPaymentAutoPaymentInfo', data.status, res);
  return res;
};

/**
 * Возвращает информацию о необходимости рекомендовать автоплатёж для неавторизованного договора
 */
export const getRecommendAutoPaymentInfo = async (
  contractName: string,
): Promise<{ isAutoPaymentBindRecommended: boolean }> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/IsAutoPaymentBindRecommendedByContractName?contractName=${contractName}`,
    {
      ...reqData,
      method: 'GET',
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getBindAutoPaymentInfo', data.status, res);
  return res;
};

/**
 * Возвращает услугу "Отложенный платёж".
 * @returns {Promise<ContractTrustedResumeProps>} Информация о состоянии платежа
 */
export const getContractTrustedResume = async (): Promise<ContractTrustedResumeProps | null> => {
  const data = await fetch(`${baseUrl}/Finances/ContractTrustedResume/Get`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  if (!data.ok) sentryWrapper('getContractTrustedResume', data.status, data);
  if (data.status === 200) {
    const res = await data.json();
    return res;
  }
  return null;
};

/**
 * Подключает услугу "Отложенный платёж" при её доступности.
 * @returns {Promise<{ status: number }>} статус подключения услуги "Отложенный платёж"
 */
export const startContractTrustedResume = async (): Promise<{
  status: number;
}> => {
  const data = await fetch(`${baseUrl}/Finances/ContractTrustedResume/Start`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  if (!data.ok) sentryWrapper('startContractTrustedResume', data.status, data);
  return data;
};

/**
 * Получает информацию по договору
 * @returns {Promise<ContractAuth>} информация о договоре
 */
export const getContractInfo = async (): Promise<ContractAuth> => {
  const data = await fetch(`${baseUrl}/Finances/Contract/GetContractInfo`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  if (!data.ok) sentryWrapper('getContractInfo', data.status, data);
  const res = await data.json();
  return res;
};

/**
 * Сбрасывает кэш
 */
export const renewCache = async (): Promise<void> => {
  try {
    await Promise.all([
      fetch(`${baseUrl}/CallToAction/CallToAction/RenewCache`, reqData),
      fetch(`${baseUrl}/City/City/RenewCache`, reqData),
      fetch(`${baseUrl}/Device/Device/RenewCache`, reqData),
      fetch(`${baseUrl}/Itv/RenewCache`, reqData),
      fetch(`${baseUrl}/Legal/RenewCache`, reqData),
      fetch(`${baseUrl}/Summary/Summary/RenewCache/RenewCache`, reqData),
    ]);
  } catch (e) {
    sentryWrapper('renewCache', e.status, e.message);
  }
};

/**
 * Регистрирует в Cordis заказ на проведение платежа с использованием СБП.
 * @returns {Promise<SbpPaymentProps>} информация о платеже через СБП
 */
export const setSbpPaymentRegisterOrderByContractName = async (
  contractName: string,
  amount: number,
): Promise<SbpPaymentProps> => {
  const data = await fetch(
    `${baseUrl}/Sbp/SbpPaymentRegisterOrderByContractName?contractName=${contractName}&amount=${amount}`,
    {
      method: 'POST',
      ...reqData,
    },
  );
  const res = await data.json();
  if (!data.ok)
    sentryWrapper('setSbpPaymentRegisterOrderByContractName', data.status, res);
  return res;
};

/**
 * Возвращает информацию о состоянии платежа с использованием СБП.
 * @returns {Promise<any>} информация о платеже через СБП
 */
export const getStaticSbpPaymentByContractName = async (
  contractName: string,
): Promise<SbpPaymentProps> => {
  const data = await fetch(
    `${baseUrl}/Sbp/GetStaticSbpPaymentByContractId?contractName=${contractName}`,
    {
      ...reqData,
    },
  );
  const res = await data.json();
  if (!data.ok)
    sentryWrapper('getStaticSbpPaymentByContractName', data.status, res);
  return res;
};

/**
 * Возвращает информацию о состоянии платежа с использованием СБП.
 * @returns {Promise<any>} информация о платеже через СБП
 */
export const getSbpPaymentCheckOrderState = async (
  contractName: string,
  qrId: string,
): Promise<SbpPaymentCheckOrderStateProps> => {
  const data = await fetch(
    `${baseUrl}/Sbp/SbpPaymentCheckOrderStateByContractName?contractName=${contractName}&qrId=${qrId}`,
    {
      ...reqData,
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSbpPaymentCheckOrderState', data.status, res);
  return res;
};

/*
 * Получает модель данных для конкурса "Мои истории"
 * @returns {Promise<PlanetaHistoryInfo>} информация о конкурсе
 */
export const getPlanetaHistoryInfo = async (): Promise<Response> => {
  const data = await fetch(
    `${baseUrl}/Promo/PlanetaHistory/GetPlanetaHistoryInfo`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) sentryWrapper('getPlanetaHistoryInfo', data.status, data);
  return data;
};

/**
 * Записывает в базу данных ответ клиента.
 * @param answer ответ пользователя
 * @returns {Promise<SetAnswerInfo>} информация о конкурсе
 */
export const setAnswer = async (answer: string): Promise<SetAnswerInfo> => {
  const data = await fetch(
    `${baseUrl}/Promo/PlanetaHistory/SetAnswer?answer=${answer}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  return data;
};

/**
 * Устанавливает значение флага true для поля is_reposted.
 * @returns {Promise<any>}
 */
export const setReposted = async (): Promise<void> => {
  const data = await fetch(`${baseUrl}/Promo/PlanetaHistory/SetReposted`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
};

/**
 * Получает доступную акцию по оплате
 * @param contractName номер договора
 * @param amount сумма
 * @param paymentType выбранный способ оплаты
 * @returns {Promise<string>} строка доступной акции
 */
export const getPaymentPromoInfo = async (
  contractName: string,
  amount: number,
  paymentType: PAYMENT_TYPE,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Promo/PaymentPromo/GetPaymentPromoInfo?contractName=${contractName}&amount=${amount}&paymentType=${paymentType}`,
    {
      ...reqData,
    },
  );
  if (!data.ok) sentryWrapper('getPaymentPromoInfo', data.status, data);
  if (data.status === 200) {
    const res = await data.json();
    return res.paymentPromoInfoCode;
  }
  return data.statusText;
};

/**
 * Сохраняет информацию о выбранном способе оплате по акции.
 * @param contractName номер договора
 * @param amount сумма оплаты
 * @param promoCode тип акции
 * @param paymentType выбранный способ оплаты
 * @param dontShowAgain состояние флага "Не показывать снова"
 * @returns {Promise<any>}
 */
export const setPromoAnswer = async (
  contractName: string,
  amount: number,
  promoCode: string,
  paymentType: PAYMENT_TYPE,
  dontShowAgain: boolean,
): Promise<DataProps> => {
  const data = await fetch(
    `${baseUrl}/Promo/PaymentPromo/SetAnswer?contractName=${contractName}&amount=${amount}&promoCode=${promoCode}&paymentType=${paymentType}&dontShowAgain=${dontShowAgain}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) sentryWrapper('setPromoAnswer', data.status, data);
  return data;
};

/*
 * Получает список всех банков
 * @returns {Promise<BanksPropsData[]>}
 */
export const getAllBanks = async (): Promise<BanksPropsData[]> => {
  const data = await fetch(`${baseUrl}/Sbp/GetAllBanks`, reqData);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getAllBanks', data.status, res);
  return res;
};

/**
 * Получает информацию об услуге Каникулы
 * @returns {Promise<VacationInfoProps>}
 */
export const getVacationInfo = async (): Promise<VacationInfoProps> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetVacationInfo`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getVacationInfo', data.status, res);
  return res;
};

/*
 * Открепляет подписку от договора.
 * @returns {Promise<void>}
 */
export const softlineUnsubscribe = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/Unbind?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('Unbind', data.status, res);

  return res.unbindDt;
};

/**
 * Получает данные о дистрибутивах для подписки
 * @returns {Promise<ShippingForSubscription>}
 */
export const getShippingForSubscription = async (
  simId: number,
): Promise<ShippingForSubscription> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/GetShippingForSubscription?simId=${simId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getShippingForSubscription', data.status, res);

  return res;
};

/**
 * Прикрепляет подписку на договоре
 * @returns {Promise<number>}
 */
export const softlineSubscribe = async (metricId: number): Promise<number> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/Bind?metricId=${metricId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('softlineSubscribe', data.status, res);

  return res.simId;
};

/**
 * Получает текст сообщения для прикрепления подписки к договору
 * @param metricId Идентификатор метрики
 * @returns {Promise<string>}
 */
export const getSubscriptionBindAgreement = async (
  metricId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/GetSubscriptionBindAgreement?metricId=${metricId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSubscriptionBindAgreement', data.status, res);
  return res.subscriptionBindAgreement;
};

/**
 * Получает текст сообщения для открепления подписки к договору
 * @param simID Идентификатор открепляемого ресурса
 * @returns {Promise<string>}
 */
export const getSubscriptionUnbindAgreement = async (
  simId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/GetSubscriptionUnbindAgreement?simId=${simId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok)
    sentryWrapper('getSubscriptionUnbindAgreement', data.status, res);
  return res.subscriptionUnbindAgreement;
};

/*
 * Возвращает информацию о подписках
 * @returns {Promise<SoftlineSubscriptionFieldsData[] >}
 */
export const getSubscriptionInfos = async (): Promise<
  SoftlineSubscriptionFieldsData[]
> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/GetSubscriptionInfos`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSubscriptionInfos', data.status, res);
  return res;
};

/**
 * Получает код авторизации для игрового портала
 * @returns {Promise<string>}
 */
export const getGamesAccountAuthCode = async (): Promise<string> => {
  const data = await fetch(`${baseUrl}/GameAccount/GetGamesAccountAuthCode`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getGamesAccountAuthCode', data.status, res);

  return res.accountAuthCode;
};

/*
 * Проверяет привязку договора для игрового портала
 * @returns {Promise<boolean>}
 */
export const checkGameAccountBind = async (): Promise<boolean> => {
  const data = await fetch(`${baseUrl}/GameAccount/CheckContractBind`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('checkGameAccountBind', data.status, res);

  return res.hasBindGameAccount;
};

/*
 * Проверяет соответствие клиента и адреса перенаправления
 * @returns {Promise<boolean>}
 */
export const checkClientIdAndBackUrl = async (
  clientId: string,
  backUrl: string,
): Promise<boolean> => {
  const data = await fetch(
    `${baseUrl}/GameAccount/CheckClientIdAndBackUrl?clientId=${clientId}&backUrl=${backUrl}`,
    {
      ...reqData,
      method: 'POST',
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('checkClientIdAndBackUrl', data.status, res);

  return res.isCorrectData;
};

/*
 * Возвращает стаж пользователя в годах
 * @returns {Promise<boolean>}
 */
export const getExperienceInfo = async (): Promise<number> => {
  const data = await fetch(`${baseUrl}/Promo/Experience/GetExperienceInfo`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getExperienceInfo', data.status, res);

  return res.experience;
};

/*
 * Производит запись участия в акции "Бонусы за доверие"
 * @returns {Promise<boolean>}
 */
export const setExperienceParticipate = async (): Promise<{
  experienceYear: number;
}> => {
  const data = await fetch(`${baseUrl}/Promo/Experience/Participate`, {
    ...reqData,
    headers: getHeadersWithAuth(),
    method: 'POST',
  });
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('setExperienceParticipate', data.status, res);

  return res?.data;
};

/*
 * Отдаёт запись участия в акции "Бонусы за доверие"
 * @returns {Promise<boolean>}
 */
export const getExperienceParticipate = async (): Promise<{
  experienceYear: number;
} | null> => {
  const data = await fetch(`${baseUrl}/Promo/Experience/GetMePlusAction`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  if (!data.ok) sentryWrapper('getExperienceParticipate', data.status, data);

  if (data.status === 200) {
    const res = await data.json();
    return res;
  }

  return null;
};

/*
 * Регистрирует qr-код для оплаты с созданием подписки
 */
export const sbpRegisterPaymentWithBinding = async (
  amount: number,
  temporaryToken?: string,
): Promise<SbpInfo> => {
  const data = await fetch(
    `${baseUrl}/Sbp/SbpRegisterPaymentWithBinding?amount=${amount}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(null, temporaryToken),
      method: 'POST',
    },
  );

  const res = await data.json();
  if (!data.ok)
    sentryWrapper('sbpRegisterPaymentWithBinding', data.status, res);

  return res;
};

/*
 * Проверяет статус подписки
 */
export const sbpCheckBinding = async (
  sbpSubscriptionId: string,
): Promise<SbpBindingCheckInfoProps> => {
  const data = await fetch(
    `${baseUrl}/Sbp/SbpCheckBinding?sbpSubscriptionId=${sbpSubscriptionId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
      method: 'POST',
    },
  );

  const res = await data.json();
  if (!data.ok) sentryWrapper('sbpCheckBinding', data.status, res);

  return res;
};

/*
 * Получает список привязанных к контрагенту подписок СБП
 */
export const getSbpBindings = async (): Promise<SbpBindingsProps[]> => {
  const data = await fetch(`${baseUrl}/Sbp/GetSbpBindings`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });

  const res = await data.json();
  if (!data.ok) sentryWrapper('getSbpBindings', data.status, res);

  return res;
};

/*
 * Производит оплату сбп по подписке
 */
export const sbpRegisterOrderByBinding = async (
  amount: number,
  paymentBindingId: number,
): Promise<SbpInfoByBinding> => {
  const data = await fetch(
    `${baseUrl}/Sbp/SbpRegisterOrderByBinding?amount=${amount}&paymentBindingId=${paymentBindingId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
      method: 'POST',
    },
  );

  const res = await data.json();
  if (!data.ok) sentryWrapper('sbpRegisterOrderByBinding', data.status, res);

  return res;
};

/*
 * Получает список банков для СБП
 */
export const getBanksWithSubscriptions = async (): Promise<
  BanksPropsData[]
> => {
  const data = await fetch(`${baseUrl}/Sbp/GetBanksWithSubscriptions`, {
    ...reqData,
  });

  const res = await data.json();
  if (!data.ok) sentryWrapper('getBanksWithSubscriptions', data.status, res);

  return res;
};

/*
 * Регистрирует qr-код для создания подписки
 */
export const sbpRegisterBinding = async (
  temporaryToken?: string,
): Promise<SbpRegisterBindingInfo> => {
  const data = await fetch(`${baseUrl}/Sbp/SbpRegisterBinding`, {
    ...reqData,
    headers: getHeadersWithAuth(null, temporaryToken),
    method: 'POST',
  });

  const res = await data.json();
  if (!data.ok) sentryWrapper('sbpRegisterBinding', data.status, res);

  return res;
};

/*
 * Возвращает информацию о состоянии платежа с использованием привязки СБП.
 * В отличие от SbpPaymentCheckOrderStateAsync дополнительно идёт в СБП в случае отсутствия успешного платежа в БД cordis, чтобы явно получить Decline.
 */
export const getSbpPaymentCheckOrderByBindingState = async (
  paymentBindingId: number,
  qrId: string,
): Promise<SbpPaymentCheckOrderByBindingStateProps> => {
  const data = await fetch(
    `${baseUrl}/Sbp/SbpPaymentCheckOrderByBindingState?paymentBindingId=${paymentBindingId}&qrId=${qrId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = await data.json();
  if (!data.ok)
    sentryWrapper('sbpPaymentCheckOrderByBindingState', data.status, res);

  return res;
};

/*
 * Метод авторизации по номеру договора/логину и пин
 */
export const authByPin = async (
  contractName: string,
  pin: string,
): Promise<{ token: string }> => {
  const data = await fetch(
    `${baseUrl}/Security/Auth/AuthByPin?contractName=${contractName}&pin=${pin}`,
    {
      ...reqData,
      method: 'POST',
    },
  );

  const res = await data.json();
  if (!data.ok) sentryWrapper('authByPin', data.status, res);

  return res;
};

/*
 * Получает информацию о маркетинг группах по городу
 */
export const getMarketingGroupsByCity = async (
  city: number,
): Promise<MarketingGroups[]> => {
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetMarketingGroupsByCity?cityId=${city}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = await data.json();
  if (!data.ok) sentryWrapper('getMarketingGroupsByCity', data.status, res);
  return res;
};

/*
 * Проверяет возможность привязки подписки к договору
 */
export const checkBindAvailability = async (
  metricId: number,
): Promise<BindData> => {
  const data = await fetch(
    `${baseUrl}/Softline/Softline/CheckBindAvailability?metricId=${metricId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('checkBindAvailability', data.status, res);
  return res;
};

/*
 * Возвращает информацию о лимитах платежа через карту
 */
export const getCardPaymentLimit = async (): Promise<{
  maxAmount: number;
  minAmount: number;
}> => {
  const data = await fetch(
    `${baseUrl}/CardPayment/CardPayment/GetCardPaymentLimit`,
    {
      ...reqData,
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getCardPaymentLimit', data.status, res);
  return res;
};

/*
 * Возвращает информацию о лимитах платежа через СБП
 */
export const getSbpPaymentLimit = async (): Promise<{
  maxAmount: number;
  minAmount: number;
}> => {
  const data = await fetch(`${baseUrl}/Sbp/GetSbpPaymentLimit`, {
    ...reqData,
  });
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSbpPaymentLimit', data.status, res);
  return res;
};
